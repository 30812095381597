window.app.service('MileageMoneyService', ['API', 'ErrFactory', function (API, ErrFactory) {
  class MileageMoney {
    constructor() {}

    static getMileageMoneyOverview(page, column, reverse, state): Promise<void> {
      return API.loadMileageMoneyReports(page, column, reverse, state)
      .catch(err => {
        if (err instanceof ErrFactory) err.notify();
        else console.error(err);
      });
    }

    static getMileageMoneyById(id: number) {
      return Promise.resolve(API.getMileageMoneyById(id))
      .catch(err => {
        if (err instanceof ErrFactory) err.notify();
        else console.error(err);
      });
    }

  }

  return MileageMoney;
}]);
